export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
  PROJECTS_ADMIN = "PROJECTS_ADMIN",
}

export default interface User {
  id?: number;
  username: string;
  email: string;
  phone: string;
  displayName: string;
  enabled: boolean;
  role: UserRole;
  enableEmailNotifications: boolean;
}

export interface UserNotificationSettings {
  enableEmailNotifications: boolean;
}
